/**
 * fontsizeName
 *
 * @typedef {'初号'|'小初'|'一号'|'小一'|'二号'|'小二'|'三号'|'小三'|'四号'|'小四'|'五号'|'小五'} FONT_SIZE_NAME
 */

/**
 * fontsizePx
 *
 * @typedef { 56 |48 | 34.7 | 32 |29.3|24|21.3|20|18.7|16|14|12} FONT_SIZE_PX
 */

/**
 * 字号列表
 */
export const fontsizeList = [
  { name: '初号', pt: 42, px: 56 },
  { name: '小初', pt: 36, px: 48 },
  { name: '一号', pt: 26, px: 34.7 },
  { name: '小一', pt: 24, px: 32 },
  { name: '二号', pt: 22, px: 29.3 },
  { name: '小二', pt: 18, px: 24 },
  { name: '三号', pt: 16, px: 21.3 },
  { name: '小三', pt: 15, px: 20 },
  { name: '四号', pt: 14, px: 18.7 },
  { name: '小四', pt: 12, px: 16 },
  { name: '五号', pt: 10.5, px: 14 },
  { name: '小五', pt: 9, px: 12 },
  // { name: '六号', pt: 7.5, px: 10 },
  // { name: '小六', pt: 6.5, px: 8.7 },
  // { name: '七号', pt: 5.5, px: 7.3 },
  // { name: '八号', pt: 5, px: 6.7 },
]

/**
 * fontFamilyValue
 *
 * @typedef {1|2|4|5} FONT_FAMILY_VALUE
 */

/**
 * fontFamilyName
 *
 * @typedef {'宋体'|'新宋体'|'黑体'|'楷体'} FONT_FAMILY_NAME
 */

/**
 * 字体列表
 */
export const fontFamilyList = [
  { value: 1, name: '宋体' },
  { value: 2, name: '新宋体' },
  { value: 4, name: '黑体' },
  { value: 5, name: '楷体' },
]

/**
 * 颜色列表
 */
export const textColorList = [{ value: '#000000', name: '黑色' }]

/**
 * 根据 fontSizeName 获取当前的 fontSize 属性
 *
 * @param {FONT_SIZE_NAME} fontsizeName
 * @returns value of {fontsizeList}
 */
export const getCurFontsizeByName = fontsizeName => {
  return fontsizeList.find(fontsize => fontsize.name === fontsizeName)
}

/**
 * 根据 fontSizePx 获取当前的 fontSize 属性
 *
 * @param {FONT_SIZE_PX} fontsizePx
 * @returns value of {fontsizeList}
 */
export const getCurFontsizeByPx = fontsizePx => {
  return fontsizeList.find(fontsize => fontsize.px === fontsizePx)
}

/**
 * 根据当前的 fontFamilyValue 获取当前的 fontFamily 属性
 *
 * @param {FONT_FAMILY_VALUE} fontFamilyValue
 * @returns value of {fontFamilyList}
 */
export const getCurFontFamilyByValue = fontFamilyValue => {
  return fontFamilyList.find(fontFamily => fontFamily.value === fontFamilyValue)
}

/**
 *
 * @param {FONT_FAMILY_NAME} fontFamilyName
 * @returns value of {fontFamilyList}
 */
export const getCurFontFamilyByName = fontFamilyName => {
  return fontFamilyList.find(fontFamily => fontFamily.name === fontFamilyName)
}
