<template>
  <div class="signVar" ref="contentWrap">
    <div class="sign-content" ref="signContent">
      <div v-show="showFileContent" class="file-content">
        <iframe
          v-if="currentFile.fileHtml"
          :srcdoc="currentFile.fileHtml || '-'"
          frameborder="0"
          :style="{
            width: `100%`,
            height: `100%`,
          }"
          class="doc-iframe"
          id="MyIFrame"
        ></iframe>

        <ul class="imgList" v-else-if="currentFile.imgList">
          <li
            class="file-img-list"
            v-for="(item, index) in currentFile.imgList"
            :key="index"
          >
            <img :src="item" alt="" :style="{ height: computedY + 'px' }" />
          </li>

          <li
            class="file-variable"
            v-for="variable in currentFile.comVarParamList"
            :key="variable.componentId"
            :style="{
              left: variable.x * ratio + 'px',
              top: calcPositionY(variable.y, variable.page, true) + 'px',
              width:
                variable.fontSize * ratio >= 12
                  ? variable.width * ratio + 'px'
                  : (variable.width * ratio) /
                      ((variable.fontSize * ratio) / 12) +
                    'px',
              height: variable.height * ratio + 'px',
              fontSize:
                variable.fontSize * ratio >= 12
                  ? variable.fontSize * ratio + 'px'
                  : 12 + 'px',
              fontFamily: getCurFontFamilyByValue(variable.font).name,
            }"
          >
            <div
              :style="{
                transform: `scale(${
                  variable.fontSize * ratio >= 12
                    ? 1
                    : (variable.fontSize * ratio) / 12
                })`,
                display: 'inline-block',
                transformOrigin: '0 0',
              }"
            >
              {{ variable.varValue ? variable.varValue : variable.varTitle }}
            </div>
          </li>
        </ul>
      </div>

      <van-form v-show="!showFileContent" @failed="onFailed" ref="form">
        <template v-if="currentFile.fileHtml">
          <!-- 数据类型 0-文本 1-数字 2-日期 -->
          <template v-for="(item, index) in currentFile.comVarParamList">
            <template v-if="item.dataType === 0">
              <van-field
                :key="index"
                v-model="item.varValue"
                input-align="right"
                :required="item.requireFlag === 1"
                :label="item.varTitle"
                :show-error="false"
                :error="false"
                :placeholder="`请输入${item.varTitle}`"
                :rules="[
                  {
                    trigger: 'onBlur',
                    required: item.requireFlag === 1,
                    validator: value => validator0(value, item),
                    message: value => message0(value, item),
                  },
                ]"
              >
                <template #button v-if="item.varTip">
                  <van-popover
                    v-model="item.showPopover"
                    theme="dark"
                    trigger="click"
                  >
                    <div
                      class="item-popover"
                      style="fontSize: 13px;padding: 11px"
                    >
                      {{ item.varTip }}
                    </div>
                    <template #reference>
                      <img
                        src="@/assets/imgs/tip.png"
                        alt=""
                        style="width: 16px"
                      />
                    </template>
                  </van-popover>
                </template>
              </van-field>
            </template>
            <template v-if="item.dataType === 1">
              <van-field
                :key="index"
                v-model="item.varValue"
                input-align="right"
                :required="item.requireFlag === 1"
                :label="item.varTitle"
                :error="false"
                :placeholder="`请输入${item.varTitle}`"
                :rules="[
                  {
                    trigger: 'onBlur',
                    required: item.requireFlag === 1,
                    validator: value => validator1(value, item),
                    message: value => message1(value, item),
                  },
                ]"
              >
                <template #button v-if="item.varTip">
                  <van-popover
                    v-model="item.showPopover"
                    theme="dark"
                    trigger="click"
                  >
                    <div
                      class="item-popover"
                      style="fontSize: 13px;padding: 11px"
                    >
                      {{ item.varTip }}
                    </div>
                    <template #reference>
                      <img
                        src="@/assets/imgs/tip.png"
                        alt=""
                        style="width: 16px"
                      />
                    </template>
                  </van-popover>
                </template>
              </van-field>
            </template>
            <template v-if="item.dataType === 2">
              <van-field
                readonly
                clickable
                input-align="right"
                :key="index"
                :error="false"
                :label="item.varTitle"
                :required="item.requireFlag === 1"
                :value="item.varValue"
                placeholder="点击选择日期"
                @click="onShowDate(item)"
                :rules="[
                  {
                    trigger: 'onChange',
                    required: item.requireFlag === 1,
                  },
                ]"
              >
                <template #button v-if="item.varTip">
                  <van-popover
                    v-model="item.showPopover"
                    theme="dark"
                    trigger="click"
                  >
                    <div
                      class="item-popover"
                      style="fontSize: 13px;padding: 11px"
                    >
                      {{ item.varTip }}
                    </div>
                    <template #reference>
                      <img
                        src="@/assets/imgs/tip.png"
                        alt=""
                        style="width: 16px"
                      />
                    </template>
                  </van-popover>
                </template>
              </van-field>
            </template>
          </template>
        </template>
        <template v-else-if="currentFile.imgList">
          <!-- 数据类型 8-文本 2-数字 3-日期 -->
          <template v-for="(item, index) in currentFile.comVarParamList">
            <template v-if="item.dataType === 8">
              <van-field
                :key="index"
                v-model="item.varValue"
                input-align="right"
                :required="item.requireFlag === 1"
                :label="item.varTitle"
                :error="false"
                :show-error="false"
                :placeholder="`请输入${item.varTitle}`"
                :rules="[
                  {
                    trigger: 'onBlur',
                    required: item.requireFlag === 1,
                    validator: value => validator0(value, item),
                    message: value => message0(value, item),
                  },
                ]"
              >
                <template #button v-if="item.varTip">
                  <van-popover
                    class="var-tip-popover"
                    v-model="item.showPopover"
                    theme="dark"
                    trigger="click"
                    @click.native.stop
                    placement="bottom-end"
                    :offset="[20, 8]"
                  >
                    <div
                      class="item-popover"
                      style="fontSize: 13px;padding: 11px"
                    >
                      {{ item.varTip }}
                    </div>
                    <template #reference>
                      <img
                        src="@/assets/imgs/tip.png"
                        alt=""
                        style="width: 16px"
                      />
                    </template>
                  </van-popover>

                  <!-- <ContentTip :tip="item.varTip" /> -->
                </template>
              </van-field>
            </template>
            <template v-if="item.dataType === 2">
              <van-field
                :key="index"
                v-model="item.varValue"
                type="number"
                input-align="right"
                :required="item.requireFlag === 1"
                :label="item.varTitle"
                :error="false"
                :show-error="false"
                :placeholder="`请输入${item.varTitle}`"
                :rules="[
                  {
                    trigger: 'onBlur',
                    required: item.requireFlag === 1,
                    validator: value => validator0(value, item),
                    message: value => message0(value, item),
                  },
                ]"
              >
                <template #button v-if="item.varTip">
                  <van-popover
                    class="var-tip-popover"
                    v-model="item.showPopover"
                    theme="dark"
                    trigger="click"
                    placement="bottom-end"
                    :offset="[20, 8]"
                  >
                    <div
                      class="item-popover"
                      style="fontSize: 13px;padding: 11px"
                    >
                      {{ item.varTip }}
                    </div>
                    <template #reference>
                      <img
                        src="@/assets/imgs/tip.png"
                        alt=""
                        style="width: 16px"
                      />
                    </template>
                  </van-popover>
                </template>
              </van-field>
            </template>
            <template v-if="item.dataType === 3">
              <van-field
                readonly
                clickable
                input-align="right"
                :name="item.varTitle"
                :key="index"
                :error="false"
                :show-error="false"
                :label="item.varTitle"
                :required="item.requireFlag === 1"
                :value="item.varValue"
                placeholder="点击选择日期"
                @click-input="onShowDate(item)"
                :rules="[
                  {
                    trigger: 'onChange',
                    required: item.requireFlag === 1,
                    message:
                      item.requireFlag === 1
                        ? `${item.varTitle}不能为空`
                        : null,
                  },
                ]"
              >
                <template #button v-if="item.varTip">
                  <van-popover
                    class="var-tip-popover"
                    v-model="item.showPopover"
                    theme="dark"
                    trigger="click"
                    placement="bottom-end"
                    :offset="[20, 8]"
                  >
                    <div
                      class="item-popover"
                      style="fontSize: 13px;padding: 11px"
                    >
                      {{ item.varTip }}
                    </div>
                    <template #reference>
                      <img
                        src="@/assets/imgs/tip.png"
                        alt=""
                        style="width: 16px"
                      />
                    </template>
                  </van-popover>
                </template>
              </van-field>
            </template>
          </template>
        </template>
      </van-form>
    </div>

    <div class="footer">
      <template v-if="showFileContent">
        <div class="btn2" @click="showFileContent = false">填写文件内容</div>
      </template>
      <template v-if="isZdd">
        <div class="btn2" @click="save">下一步</div>
      </template>
      <template v-else>
        <div class="btn1" @click="catFile">查看文件</div>
        <div class="btn2" @click="save">保存</div>
      </template>
    </div>
    <van-calendar
      v-model="show"
      class="calendar"
      @confirm="onConfirm"
      color="#1676ff"
      :min-date="new Date(1262278861000)"
    />
  </div>
</template>
<script>
  import { getCurFontFamilyByValue } from '@/common/font'
  import { mapState } from 'vuex'
  import { Dialog, Toast } from 'vant'
  import Moment from 'moment'
  import { validateNumber } from '@/utils/validate'
  import textBecomeImg from '@/utils/textBecomeImg'
  import { paginationHeight } from '@/common/filePreviewConfig'
  import { calcRatio } from '@/utils/calcRatio'
  import { getBodyClientRect } from '@/utils/dom'
  import { SAVE_DRAFT_STEP } from '@/common/enum'
  // import ContentTip from './contentTip.vue'

  export default {
    components: {
      // ContentTip,
    },
    data() {
      return {
        show: false,

        // 是否展示文件内容
        showFileContent: false,

        saving: false,

        masterId: undefined,
        curFileIndex: undefined,
      }
    },
    watch: {
      showFileContent: {
        handler(newValue) {
          this.$emit('onFileContentShow', newValue)
        },
        immediate: true,
      },
    },
    computed: {
      ...mapState({
        computedY: state => state.contractSign.computedY,
        ratio: state => state.contractSign.ratio,
        ratio2: state => state.contractSign.ratio2,
      }),
      isZdd() {
        return this.$store.getters['system/isZdd']
      },
      fileList() {
        return this.launchInfo?.fileList
      },
      currentFile() {
        return (
          this.fileList.find(
            file => Number(file.fileIndex) === Number(this.curFileIndex)
          ) ||
          this.fileList[0] ||
          {}
        )
      },
      launchInfo() {
        return this.$store.state.sign.launch
      },
    },
    created() {
      this.masterId = this.$route.query.masterId
      if (this.masterId) {
        this.draft()
      }
    },
    mounted() {
      const { clientWidth } = getBodyClientRect()
      this.$store.commit('contractSign/setRatio', clientWidth)

      this.calcContentWrapHeight()
      if (this.currentFile.fileHtml) {
        const iframe = document.getElementById('MyIFrame')
        iframe.onload = () => {
          // hack 处理 微信环境下 iframe 在某些机型上会出现白屏的情况，此时再重新write一次可以解决大多数情况
          iframe.contentDocument.write(this.currentFile.fileHtml)
          const iframeDoc = iframe.contentDocument
          const styleFragment = document.createElement('style')
          styleFragment.innerText = `body{margin:0;} #tinymce{width:100% !important;margin:0;overflow-x:hidden;} body{width:100%;} .variables{color: #1676FF;background: #CCE2FF;}`
          iframeDoc.head.append(styleFragment)
          iframeDoc.body.contentEditable = false
        }
      }
    },
    methods: {
      textBecomeImg,
      getCurFontFamilyByValue,

      async draft() {
        try {
          const model = {
            masterId: this.masterId,
          }
          await this.$store.dispatch('sign/getContractDraft', model)
        } catch (error) {
          console.error(error)
        }
      },

      async save() {
        let toast
        try {
          if (this.saving) return
          this.saving = true
          await this.$refs.form.validate()

          toast = Toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
          })
          this.launchInfo.launchSign = 0
          // 默认跳转自己签署页面(zdd)
          // 其他情况需判断
          this.launchInfo.numStep = SAVE_DRAFT_STEP.SIGN_SELF
          this.launchInfo.step = SAVE_DRAFT_STEP.SIGN_VAR_PAGE
          const res = await this.$store.dispatch('sign/saveWithStatus', {
            launchInfo: this.launchInfo,
            vm: this,
          })
          if (res) {
            // 这里先默认跳转签署页面(zdd)
            // 如果要做正常的发起流程，则需要还需要判断是否需要自己去发起
            this.$router.replace({
              name: 'LaunchSignSeal',
              query: {
                masterId: res.masterId,
              },
            })
          }
        } catch (error) {
          console.error('error', error)
          this.saving = false
        } finally {
          toast && toast.close()
        }
      },

      calcContentWrapHeight() {
        this.$nextTick(() => {
          const { contentWrap, signContent } = this.$refs
          signContent.style.height = `calc(100vh - 50px - 56px)`
        })
      },
      catFile() {
        window.sessionStorage.isBackCatFile = true
        this.showFileContent = true
        // const { subId, businessId, isLauncher } = this.$route.query
        // this.$router.push({
        //   path: '/catFile',
        //   query: {
        //     subId,
        //     businessId,
        //     isLauncher,
        //   },
        // })
      },
      onFailed() {},
      formatDate(date, dateFormat) {
        if (dateFormat === 0) {
          return Moment(date).format('yyyy/MM/DD')
        }
        if (dateFormat === 1) {
          return Moment(date).format('yyyy-MM-DD')
        }
        if (dateFormat === 2) {
          return Moment(date).format('yyyy年MM月DD日')
        }
        return Moment(date).format('yyyy年MM月DD日')
      },
      onShowDate(item) {
        this.dateItem = item
        this.show = true
      },
      onConfirm(date) {
        this.show = false
        this.dateItem.varValue = this.formatDate(date, this.dateItem.dateFormat)
      },
      validator0(value, item) {
        const { requireFlag, minLength, maxLength, varTitle } = item
        const min = minLength || 0
        if (requireFlag === 1 && !value) {
          return false
        }
        if (minLength && value && value.length < minLength) {
          return false
        }
        if (maxLength && value && value.length > maxLength) {
          return false
        }
        return true
      },
      message0(value, item) {
        const { requireFlag, minLength, maxLength, varTitle, varValue } = item
        const min = minLength || 0
        if (requireFlag === 1 && !value) {
          return `${varTitle}不能为空`
        }
        if (minLength && value && value.length < minLength) {
          return `长度在 ${min} 到 ${maxLength} 个字符`
        }
        if (maxLength && value && value.length > maxLength) {
          return `长度在 ${min} 到 ${maxLength} 个字符`
        }
        return true
      },

      // 数字类型
      validator1(value, item) {
        const { requireFlag, minLength, maxLength, varTitle } = item
        const min = minLength || 0
        if (requireFlag === 1 && !value) {
          return false
        }
        if (value && maxLength && value > maxLength) {
          return false
        }
        if (value && !validateNumber(value)) {
          return false
        }
        if (value && value < min) {
          return false
        }
        return true
      },
      message1(value, item) {
        const { requireFlag, minLength, maxLength, varTitle } = item
        const min = minLength || 0
        if (requireFlag === 1 && !value) {
          return `${varTitle}不能为空`
        }
        if (value && !validateNumber(value)) {
          return `请输入数字类型的值`
        }
        if (value && maxLength && value > maxLength) {
          return `最大值为${maxLength}`
        }
        if (value && value < min) {
          return `最小值为${min}`
        }
        return ''
      },
      calcPositionY(y, page, ratio) {
        if (ratio) {
          return (
            y * this.ratio +
            (page - 1) * (this.computedY + paginationHeight * this.ratio2)
          )
        }
        return (
          y + (page - 1) * (this.computedY + paginationHeight * this.ratio2)
        )
      },
    },
  }
</script>

<style lang="less">
  .signVar {
    .van-field__error-message {
      text-align: right;
      padding-right: 10px;
    }
  }
</style>
<style lang="less" scoped>
  .signVar {
    .item-popover {
    }
    .sign-content {
      background-color: white;
    }

    /deep/ .van-cell {
      // height: 54px;
      // line-height: 34px;
      overflow: visible;
    }

    .sign-content {
      padding-bottom: 24px;

      .file-content {
        height: 100%;
        .imgList {
          height: 100%;
          background: #f4f5f6;
          position: relative;
          .file-img-list {
            height: 100%;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .file-img-list + .file-img-list {
            margin-top: 20px;
          }
          .file-variable {
            position: absolute;
          }
        }
      }
    }

    .footer {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 56px;
      background: #ffffff;
      box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;

      .btn1 {
        flex: 1;
        height: 44px;
        background: #ffffff;
        border: 1px solid #2f86f6;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #2f86f6;
        line-height: 44px;
        margin-right: 11px;
      }

      .btn2 {
        flex: 1;
        height: 44px;
        background: #1676ff;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 44px;
      }
    }
    .calendar {
      /deep/.van-calendar__body {
        .van-calendar__month {
          .van-calendar__days {
            .van-calendar__day {
              height: 44px;
            }
          }
        }
      }
    }
  }
</style>
