/**
 * 验证手机号
 * @param {*} value
 */
function validateMobileNumber(value) {
  return /^1[0-9]{2}\d{8}$/.test(value)
}

/**
 * 验证email
 * @param {*} value
 */
function validateEmail(value) {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
    value
  )
}

/**
 * 验证身份证号
 * @param {*} value
 */
function validateIdentityNumber(value) {
  return /^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$/.test(
    value
  )
}

/**
 * 验证密码规则，字母和数字组合
 * @param {*} value
 */
function validatePassword(value) {
  return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\x21-\x7e])[A-Za-z\d\x21-\x7e]{8,16}$/.test(
    value
  )
}

/**
 * 验证是否为数字
 * @param {*} value
 */
function validateNumber(value) {
  return /^[0-9]+.?[0-9]*$/.test(value)
}

export {
  validateMobileNumber,
  validateEmail,
  validateIdentityNumber,
  validatePassword,
  validateNumber,
}
